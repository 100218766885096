<template>
    <div class="your-opinion" style="position: relative;">
        <div class="background" />
        <div class="row" style="background: white;">
            <div class="col-md-4 d-flex align-items-start flex-column">
                <div class="main-title">
                    Vaše mnenje
                </div>
                <div class="mt-1 mt-md-auto">
                    <router-link :to="{name: 'faq'}">
                        > pogosta vprašanja
                    </router-link>
                </div>
            </div>
            <div class="col-md-8" >
                <div class="your-opinion-content mb-1 mt-1">
                    <div class="title-content mb-1">
                        Vaše mnenje šteje
                        Pomagajte nam biti še boljši!
                    </div>
                    eSoseska je nov upravniški portal z veliko vizijo, ki stremi k inovacijam in napredku.
                    Zavedamo se, da nismo brez napak. Skupaj z vašo pomočjo si želimo ustvariti portal katerega boste z veseljem obiskovali.
                    Podajte svoje mnenje in predloge kako bi še izboljšali naš portal.
 
                    Da bomo skupaj postali še boljši!
 
                    Hvala vam!
                </div>
                <div v-if="!messageSend">
                    <div>
                        <b-form-input class="input-topic" v-model="topic" placeholder="Tema" name="title"></b-form-input>
                    </div>
                    <div class="d-flex mt-1">
                        <b-form-input class="input-topic mr-1" v-model="name" placeholder="Ime in priimek" name="name"></b-form-input>
                        <b-form-input class="input-topic" v-model="mail" placeholder="E-mail" name="mail"></b-form-input>
                    </div>
                    <div>
                        <b-form-textarea class="textarea-opinion mt-1" placeholder="Vaše mnenje" v-model="opinion" no-resize rows="8"></b-form-textarea>
                        <div class="button-background p-1 d-flex justify-content-end align-items-center ">
                            <b-spinner class="mr-3" label="Spinning" v-if="sending"></b-spinner>
                            <b-button pill class="button-opinion px-5" @click="sendMessage" v-else>Oddaj svoje mnenje</b-button>
                        </div>
                    </div>
                </div>
                <div class="mt-5" v-if="messageSend">
                    <div class="button-background p-5 d-flex justify-content-center text-finish">
                        Hvala za poslano!
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { BFormInput, BFormTextarea, BButton, BSpinner } from 'bootstrap-vue'

    export default {
        components:{
            BFormInput,
            BFormTextarea,
            BButton,
            BSpinner
        },
        data() {
            return {
                topic: '',
                opinion: '',
                name:'',
                mail:'',
                messageSend: null,
                sending: false
            }
        },
        methods:{
            async sendMessage() {
                this.sending = true
                this.object = {}
                try {
                    this.object.name = this.name
                    this.object.email_from = this.mail
                    this.object.telephone_number = ''
                    this.object.subject = `Vaše mnenje - ${  this.topic}`
                    this.object.message = this.opinion

                    await this.$http.post('/api/user/v1/send_mail/vase_mnenje', this.object)
                    this.$printSuccess('Email je bil uspešno poslan')
                    this.object.topic = ''
                    this.object.opinion = ''
                    this.messageSend = true

                } catch (error) {
                    this.$printError(`Pri pošiljanju emaila je prišlo do napake!\n${error.message}`)
                } finally {
                    this.sending = false
                }
                
            }
        }
    }
</script>


<style scoped>
    .your-opinion-content{
        font-size: 17px !important;
    }

    .background{
        position: absolute;
        top: 50px;
        height: 150px;
        width: 9999px;
        margin-left: -2000px !important;
        margin-right: -2000px !important;
        background: linear-gradient(#72a5d84b, white) !important;
        z-index: -1;
    }

    .main-title{
        font-size: 3rem;
        font-weight: 900;
    }

    .title-content{
        font-weight: bold;
        font-size: 1.3rem;
    }

    .borderBottom{
        border-bottom: 1px solid black;
    }

    .text-finish{
        color: #72A5D8 !important;
        font-size: 38px;
        font-weight: bold;
    }

</style>

<style>

    .button-background{
        background: #E7E6E6 !important;
    }

    .textarea-opinion{
        background: #E7E6E6 !important;
        border: none !important;
        border-radius: 0px !important;
    }

    .input-topic{
        background: #E7E6E6 !important;
        border: none !important;
        border-radius: 0px !important;
    }
  

    .input-topic::placeholder, .textarea-opinion::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: black;
        opacity: 1; /* Firefox */
    }

    .input-topic:-ms-input-placeholder, .textarea-opinion:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: black;
    }

    .input-topic::-ms-input-placeholder, .textarea-opinion::-ms-input-placeholder { /* Microsoft Edge */
        color: black;
    }


</style>
